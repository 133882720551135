html {
    &.dark {
        #root {
            background: #000;
        }

        ::-moz-selection {
            background: #c01cc075 !important;
            color: white,
        }

        ::selection {
            background: #c01cc075 !important;
            color: white,
        }

        .vertical-timeline-element--work .vertical-timeline-element-content {
            background-color: rgba(39, 39, 42, 0.5);
            color: rgba(244, 244, 245, 1);
        }

        .vertical-timeline-element-content-arrow {
            border-right: 7px solid rgba(39, 39, 42, 0.5) !important;
        }

        .vertical-timeline::before {
            --line-color: #fff !important;
        }

        .vertical-timeline-element-icon {
            background-color: rgba(39, 39, 42, 1) !important;
            display: flex;
            justify-content: center;
        }
    }
}

.vertical-timeline-element--work .vertical-timeline-element-content {
    background-color: rgba(250, 250, 250, 1);
    color: rgba(39, 39, 42, 1);
}

.vertical-timeline-element-content h2 {
    color: #c01cc0 !important;
}

.vertical-timeline-element-content-arrow {
    border-right: 7px solid rgba(250, 250, 250, 1) !important;
}

.res_lul {
    list-style: disc;
    padding-left: 5px;
}

.vertical-timeline::before {
    --line-color: rgba(39, 39, 42, 1) !important;
}

.vertical-timeline-element-icon {
    background-color: white !important;
    display: flex;
    justify-content: center;
}


html {
    font-family: 'Josefin Sans' !important;
}

::-moz-selection {
    background: #c01cc075 !important;
    color: white,
}

::selection {
    background: #c01cc075 !important;
    color: white,
}

.imaged-text {
    margin-right: -.0625rem;
    margin-left: -.0625rem;
    padding-right: .0625rem;
    padding-left: .0625rem;
    background-image: linear-gradient(to right, #c01cc075 100%, #fff 0);
    background-position: 0 .6875rem;
    background-repeat: repeat-x;
    background-size: 100% .1625rem;
    background-image: linear-gradient(to right, #c01cc075 100%, #fff 0);
    background-position: 0 80%;
    background-repeat: repeat-x;
    background-size: 100% 15%;
    transition: all 0.3s;
}

.imaged-text:hover {
    background-size: 100% 20%;
    transition: all 0.3s;
}

.tggl:focus-visible {
    border: 0;
}

.carousel-item+.carousel-item {
    margin-left: 10px !important;
}

.font-size-30px{
    font-size: 30px;
}